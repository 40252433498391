.ribbon-container {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e59d3b;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000;
}

.icon-container {
  border-radius: 0 10px 10px 0;
  width: 40px;
  height: 50px;
  background: #f4a37d;
  color: black;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: flex-start; /* Align content to the start (left) by default */
  position: relative; /* Position relative for absolute positioning of additional elements */
  transition: width 0.2s ease;
  cursor: pointer;
  overflow: hidden; /* Ensure content doesn't overflow the container */
}

.icon-container:hover {
  width: 135px; /* Increase the width to accommodate the text */
}

.icon-container .icon {
  margin-left: auto; /* Push the icon to the right side of the container */
  transition: margin-left 0.2s ease;
}

.icon-container:hover .icon {
  margin-left: auto; /* Ensure the icon stays on the right side */
}

.icon-container::after {
  content: "Order Now"; /* Replace with the phone number */
  position: absolute;
  left: 10px; /* Position text to the left of the icon */
  top: 50%;
  transform: translateY(-50%); /* Center text vertically */
  white-space: nowrap;
  opacity: 0; /* Initially hide the text */
  transition: opacity 0.2s ease;
}

.icon-container:hover::after {
  opacity: 1; /* Show the text on hover */
}

/* Second Ribbon */

.ribbon-container1 {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #df9031;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000;
}

.icon-container1 {
  border-radius: 0 10px 10px 0;
  width: 40px;
  height: 50px;
  background: #8c7356s;
  color: black;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: flex-start; /* Align content to the start (left) by default */
  position: relative; /* Position relative for absolute positioning of additional elements */
  transition: width 0.2s ease;
  cursor: pointer;
  overflow: hidden; /* Ensure content doesn't overflow the container */
}

.icon-container1:hover {
  width: 135px; /* Increase the width to accommodate the text */
}

.icon-container1 .icon {
  margin-left: auto; /* Push the icon to the right side of the container */
  transition: margin-left 0.2s ease;
}

.icon-container1:hover .icon {
  margin-left: auto; /* Ensure the icon stays on the right side */
}

.icon-container1::after {
  content: "Email"; /* Replace with the phone number */
  position: absolute;
  left: 10px; /* Position text to the left of the icon */
  top: 50%;
  transform: translateY(-50%); /* Center text vertically */
  white-space: nowrap;
  opacity: 0; /* Initially hide the text */
  transition: opacity 0.2s ease;
}

.icon-container1:hover::after {
  opacity: 1; /* Show the text on hover */
}

/* #rd Ribbon */
.ribbon-container2 {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fcb247;
  border-radius: 0 10px 10px 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 1000;
}

.icon-container2 {
  border-radius: 0 10px 10px 0;
  width: 40px;
  height: 50px;
  background: #d3d3d3;
  color: black;
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: flex-start; /* Align content to the start (left) by default */
  position: relative; /* Position relative for absolute positioning of additional elements */
  transition: width 0.2s ease;
  cursor: pointer;
  overflow: hidden; /* Ensure content doesn't overflow the container */
}

.icon-container2:hover {
  width: 135px; /* Increase the width to accommodate the text */
}

.icon-container2 .icon {
  margin-left: auto; /* Push the icon to the right side of the container */
  transition: margin-left 0.2s ease;
}

.icon-container2:hover .icon {
  margin-left: auto; /* Ensure the icon stays on the right side */
}

.icon-container2::after {
  content: "WhatsApp"; /* Replace with the phone number */
  position: absolute;
  left: 10px; /* Position text to the left of the icon */
  top: 50%;
  transform: translateY(-50%); /* Center text vertically */
  white-space: nowrap;
  opacity: 0; /* Initially hide the text */
  transition: opacity 0.2s ease;
}

.icon-container2:hover::after {
  opacity: 1; /* Show the text on hover */
}

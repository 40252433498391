@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply py-1.5 px-3 text-center bg-blue-700 rounded-md text-white;
  }
}
@import "./fonts.css";

.ribbon-item {
  @apply flex items-center justify-center md:justify-start bg-gray-800 text-white p-2 cursor-pointer transition-all duration-300;
}

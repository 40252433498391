/* Define the main container to use flexbox */
body,
html,
#root {
  height: 100%;
  margin: 0;
}

.flex-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Full viewport height */
}

.flex-content {
  flex: 1; /* Take up the remaining space */
}

.footer-background {
  background-color: #3b3b3b; /* Adjust this color to match the footer background in the image */
}

.footer-font {
  font-family: "Moseo Sans", sans-serif;
}

/* Blue line styles */
.blue-line {
  display: block;
  width: 220px; /* Fixed width */
  height: 2px;
  background-color: #00ffff; /* Adjust the color to match the blue in the image */
  margin: 8px auto; /* Center the line */
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .blue-line {
    width: 220px; /* Ensure consistent width for smaller devices */
  }
}

/* Ensure proper spacing and layout for medium devices */
@media (min-width: 768px) and (max-width: 1024px) {
  .footer-background {
    padding: 2rem; /* Adjust padding for medium devices */
  }

  .footer-background h2 {
    margin-top: 2rem; /* Adjust margin for medium devices */
  }

  .footer-background address,
  .footer-background ul {
    margin-top: 1rem; /* Adjust margin for medium devices */
  }

  .footer-background ul {
    align-items: center; /* Center align the items in the list */
  }
}

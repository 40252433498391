@font-face {
  font-family: "Moseo";
  src: url("./assets/fonts/Museo300-Regular.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Moseo Sans";
  src: url("./assets/fonts/Museo700-Regular.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}
